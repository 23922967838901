import JshModule from '@core/scripts/helper/jsh-module';
import {showPictureAtVideoError} from "./video-fallback.js";
import "@mux/mux-player";

/**
 * only for immutable videos, i.e. half size Teaser where Video is not overridden on desktop
 **/
do {
    const {moduleScope} = JshModule('teaser/video-handler-immutable');

    if (moduleScope !== null) {
        moduleScope.addEventListener("error", () => {
            showPictureAtVideoError(moduleScope);
        });
    }

} while (document.querySelector('[jsh-module="teaser/video-handler-immutable"]') !== null);
